import * as React from 'react';
import * as styles from './CareerContent.module.scss';

const CareerContent = ({ children, isCareerContainer }) => {
  return (
    <div className={[ 
        styles.container,
        isCareerContainer ? styles.isCareerContainer : null,
      ].join(" ")}
      >
      { children }
    </div>
  )
}

export default CareerContent;