import React from "react";
import * as styles from "./FormFileInput.module.scss";

function AttachmentButton({ handleAddAttachment, name, label }) {
	return (
		<label className={styles.attachmentButton} for={name}>
			<div>
				{label}
				<input className={styles.hiddenInput} id={name} type='file' name={name} onChange={(e) => handleAddAttachment(e.target.files[0])} />
			</div>
		</label>
	);
}

function AttachedFile({ attachment }) {
	return (
		<div className={styles.attachmentFile} href='#'>
			{attachment.name}
		</div>
	);
}

export default class FormGroupFile extends React.Component {
	state = {
		attachment: {},
	};

	handleAddAttachment = (file) => {
		this.setState({ attachment: file });
	};

	render() {
		const { attachment } = this.state;

		return (
			<div className={styles.inputFileContainer}>
				<div className={styles.inputFile}>
					<AttachedFile attachment={attachment} />
					<AttachmentButton
						name={this.props.name} label={this.props.uploadDocumentLabel}
						handleAddAttachment={this.handleAddAttachment}
					/>
				</div>
				<small className={styles.inputFileSmallText}>
					{this.props.maximumFilesizeLabel}
				</small>
			</div>
		);
	}
}
