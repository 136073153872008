import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import PageTitle from "../components/PageTitle";
import PageHeader from "../components/PageHeader";
import CareerContent from "../components/CareerContent";
import CareerForm from "../components/CareerForm";
import * as styles from "../components/CareerContent.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const PressSingle = ({ data }) => {
	const { contentfulCareerElement, allContentfulLabel } = data;
	return (
		<Layout>
			<main>
				<PageHeader hasNoBottomMargin>
					<PageTitle isSmall>
						<div>{contentfulCareerElement.subtitle}</div>
						<h1>{contentfulCareerElement.title}</h1>
					</PageTitle>
				</PageHeader>
				<Section hasMaxWidth isCareerContentSection>
					<CareerContent isCareerContainer>
						<div className={styles.content}>{renderRichText(contentfulCareerElement.body)}</div>
						<CareerForm positionTitle={contentfulCareerElement.title} labels={allContentfulLabel.nodes}/>
					</CareerContent>
				</Section>
			</main>
		</Layout>
	);
};

export default PressSingle;

export function Head({ data }) {
	const { contentfulCareerElement } = data;
	return <title>{contentfulCareerElement.title} | Derschlag</title>;
}

export const query = graphql`
	query CareerBySlug($locale: String!, $slug: String!) {
		contentfulCareerElement(slug: { eq: $slug }, node_locale: { eq: $locale }) {
			...CareerFields
		}
		allContentfulLabel (filter: {node_locale: {eq: $locale}}) {
			nodes {
				... LabelFields
			}
		}
	}
`;
